<template>
  <bg-modal
    :value="isOpen"
    title="Yakin menghapus biaya tambahan penyewa?"
    description="Biaya tambahan penyewa yang dihapus tidak dapat dikembalikan."
    desktop-size="sm"
    :closable="false"
    :close-on-click-backdrop="false"
  >
    <template #footer>
      <div class="flex justify-end">
        <bg-button :loading="isLoading" class="mr-16" @click="deleteCost"
          >Hapus</bg-button
        >
        <bg-button variant="primary" @click="closeModal">Batal</bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgModal, BgButton } from 'bangul-vue';
import detailTransferApi from '@admin_endpoints/detail-transfer';

export default {
  name: 'DeleteCostModal',

  components: {
    BgModal,
    BgButton,
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    costId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    approvalId() {
      return this.$route.params.approval_id;
    },
    invoiceId() {
      return this.$route.params.disbursement_invoice_id;
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:costId', null);
    },

    async deleteCost() {
      if (this.costId !== null) {
        this.isLoading = true;

        try {
          await detailTransferApi.deleteAdditionalCost(
            this.approvalId,
            this.invoiceId,
            this.costId
          );

          this.$emit('action-success-delete');
          this.$toast.show('Biaya Tambahan berhasil dihapus.');
        } catch (error) {
          const errorMessage =
            error?.response?.data.issue?.message ||
            'Gagal saat menghapus biaya tambahan.';

          this.$toast.show(errorMessage);
        } finally {
          this.isLoading = false;
          this.closeModal();
        }
      }
    },
  },
};
</script>
